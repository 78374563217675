body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
}

header {
  background-color: #333;
  color: #fff;
  padding: 10px 20px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

nav ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
}

nav li {
  margin-left: 20px;
}

nav a {
  color: #fff;
  text-decoration: none;
}

nav a:hover {
  text-decoration: underline;
}

.home-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

.social-links {
  margin-bottom: 20px;
  border-bottom: 1px solid #ccc;
  padding-bottom: 5px;
}

.social-links a {
  margin-right: 10px;
  
}

.about-me, .communities, .writing, .investment {
  margin-top: 20px;
}

h1 {
  margin-top: 20px;
  margin-bottom: 10px;
  
}

h2 {
  margin-top: 20px;
  border-bottom: 1px solid #ccc;
  padding-bottom: 5px;
}

p {
  margin-bottom: 10px;
}

footer {
  text-align: center;
  padding: 10px 0;
  background-color: #333;
  color: #fff;
  position: fixed;
  width: 100%;
  bottom: 0;
}
